import {
  Box,
  Divider,
  Typography,
  CardActions,
  Collapse,
  CardContent,
  Card,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useContext } from "react";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SiteContext } from "../../context";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function MobileInvoice() {
  const { transactionDetail, setTransactionDetail } = useContext(SiteContext);

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const LineItem = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  }));

  const TotalLineItem = styled(LineItem)({
    fontWeight: "bold",
  });

  return (
    <div>
      <Card
        sx={{
          p: 1,
          backgroundColor: "#000314",
          color: "#fff",
          borderRadius: "12px",
        }}
      >
        <CardContent>
          <LineItem sx={{ mt: 1, fontWeight: 700 }}>
            <Typography variant="body2">Transaction ID</Typography>
            <Typography variant="body2">
              {transactionDetail.transactionId
                ? transactionDetail.transactionId
                : ""}
            </Typography>
          </LineItem>
          <Divider style={{ borderColor: "#28303F" }} />
        </CardContent>

        {/* <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <LineItem sx={{ mt: 1, mb: 1, fontWeight: 700 }}>
              <Typography variant="body2">Subtotal</Typography>
              <Typography variant="body2">${transactionDetail.amount ? transactionDetail.amount : ""}</Typography>
            </LineItem>
            <LineItem sx={{ mt: 1, mb: 1, fontWeight: 700 }}>
              <Typography variant="body2">Tax</Typography>
              <Typography variant="body2">${transactionDetail.tax ? transactionDetail.tax : "0.00"}</Typography>
            </LineItem>
            <Divider style={{ borderColor: "#28303F" }} />
          </CardContent>
        </Collapse> */}
        <CardContent>
          <TotalLineItem sx={{ fontWeight: 700 }}>
            <Typography variant="body2">Total</Typography>
            <Typography variant="body2">
              ₹{transactionDetail.amount ? transactionDetail.amount : ""}
            </Typography>
          </TotalLineItem>
        </CardContent>

        {localStorage.getItem("remainingSeconds") > 0 ? (
          <Grid
            style={{
              textAlign: "center",
              fontSize: 14,
              color: "red",
              fontWeight: "bold",
              textDecoration: "underline",
              marginBottom: 10,
            }}
            display={{ xs: "block", md: "none" }}
          >
            <div>
              {localStorage.getItem("remainingSeconds")} SECONDS REMAINING
            </div>

            {/* {minutes === 0 && remainingSeconds === 0 && <p>Timer expired!</p>} */}
          </Grid>
        ) : (
          <></>
        )}
        {/* <CardActions>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <Box
              sx={{
                color: "#fff",
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span>{!expanded ? "Hide Details" : "Show Details"}</span>
              <ExpandMoreIcon
                sx={{
                  transform: !expanded ? "rotate(0deg)" : "rotate(180deg)",
                }}
              />
            </Box>
          </ExpandMore>
        </CardActions> */}
      </Card>
    </div>
  );
}
