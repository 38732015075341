import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Button,
  Grid,
  Link,
  CircularProgress,
  Modal,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import PaymentInvoice from "../components/PaymentInvoice";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import PaymentMethod from "../components/PaymentMethod";
import ImpsPayment from "../components/PaymentType/ImpsPayment";
import PaymentUpi from "../components/PaymentUpi";
import Info from "../assets/Info.svg";
import UPIIcon from "../assets/Upi.svg";
import Logo from "../assets/logo.svg";
import IMPSIcon from "../assets/IMPS.svg";
import { fetchInititalTransactionDetails } from "../utils/service";
import { useNavigate, useParams } from "react-router-dom";
import { SiteContext } from "../context";
import Note from "../components/Note";
// import Logo from "../assets/LogoMobile.svg";
import ToastMessage from "../components/Toast";
import { VIDEO_URL_IMPS, VIDEO_URL_UPI } from "../constant";
// import ReactPlayer from "react-player";

export default function Payment() {
  const params = useParams();
  const navigate = useNavigate();

  const [showTimer, setShowTimer] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState("UPI");
  const [isCheckout, setIsCheckout] = useState(false);
  const [timerValue, setTimerValue] = useState(30);
  const [showVideo, setShowVideo] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [toastMsg, setToastMsg] = useState();

  const paymentId = params?.paymentId ?? null;
  const transactionId = params?.transactionId ?? null;
  const [transactionDetail, setTransactionDetail] = useState({});

  const fetchInitialDetails = async () => {
    setisLoading(true);
    try {
      const result = await fetchInititalTransactionDetails(
        paymentId,
        transactionId
      );
      if (result?.status === "success") {
        let currentStep = "";
        if (result?.data?.availableMethod == "imps") {
          currentStep = "IMPS";
        } else if (result?.data?.availableMethod == "upi") {
          currentStep = "UPI";
        }

        setTransactionDetail({
          ...transactionDetail,
          loading: false,
          step: currentStep,
          ...result?.data,
        });

        if (result?.data.availableMethod !== "UPI,IMPS") {
          setSelectedPayment(result?.data.availableMethod);
          setIsCheckout(true);
        }
        setisLoading(false);
      }
    } catch (error) {
      setisLoading(false);
      setTransactionDetail({
        ...transactionDetail,
        loading: false,
      });
      setToastMsg(error?.response?.data?.message || "Something went wrong");
      return navigate("/failed", {
        state: {
          errorMsg: error?.response?.data?.message || "Something went wrong",
          status: error?.response?.data?.status,
        },
      });
    }
  };

  useEffect(() => {
    fetchInitialDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRadioCheckout = () => {
    setIsCheckout(true);
  };

  const handleClose = () => {
    setToastMsg();
  };

  const InvoiceCardWrapper = styled(Box)(({ theme }) => ({
    background: "#fff",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "center",
      background: "#000314", // Gradient for large screens (adjust colors as needed)
    },
  }));

  const PaymentOption = ({ selected, icon, title, description }) => {
    return (
      <Paper
        variant="outlined"
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "16px",
          borderColor: selected ? "primary.main" : "transparent",
          boxShadow: "none",
          justifyContent: "start",
          width: "100%",
          backgroundColor: "#fff",
        }}
      >
        <Box sx={{ display: "flex", flexGrow: 1, alignItems: "center" }}>
          <Box sx={{ marginRight: "16px", color: "text.secondary" }}>
            <img src={icon} alt="" />
          </Box>
          <Box>
            <Typography variant="subtitle1">{title}</Typography>
            <Typography variant="caption">{description}</Typography>
          </Box>
        </Box>
        {selected ? (
          <RadioButtonCheckedIcon color="primary" />
        ) : (
          <RadioButtonUncheckedIcon />
        )}
      </Paper>
    );
  };

  return (
    <SiteContext.Provider
      value={{
        transactionDetail,
        setTransactionDetail,
      }}
    >
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "100vh",
          }}
        >
          <Typography variant="h6" sx={{ p: 1 }}>
            Please wait..
          </Typography>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container sx={{ height: "100vh" }}>
          <ToastMessage
            open={toastMsg}
            message={toastMsg}
            handleClose={handleClose}
          />
          {/* <Modal
            open={showVideo}
            onClose={() => setShowVideo(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Modal.Body style={{ margin: "10px auto" }}>
              <Grid item sm={12}>
                <Box>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Text in a modal
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Duis mollis, est non commodo luctus, nisi erat porttitor
                    ligula.
                  </Typography>
                </Box>

                {showVideo && (
                  <ReactPlayer
                    className="react-player"
                    url={transactionDetail?.videoLink || ""}
                    width={"100%"}
                  />
                )}
              </Grid>
            </Modal.Body>
          </Modal> */}
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            sx={{
              overflowY: "auto",
              // display: "flex",
              justifyContent: "center",
            }}
            display={{ xs: showTimer ? "none" : "flex", md: "flex" }}
          >
            <InvoiceCardWrapper>
              <PaymentInvoice />
            </InvoiceCardWrapper>
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            md={6}
            sx={{
              overflowY: "auto",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid
              item
              xs={12}
              md={10}
              sx={{ overflowY: "auto", backgroundColor: "#fff" }}
            >
              <Box
                sx={{
                  p: 0,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <div></div>
                  {/* <Link display={{ xs: "none", md: "block" }}>
                    {transactionDetail.logo ? (
                      <img
                        src={`data:image/png;base64,${transactionDetail.logo}`}
                        alt=""
                        height={42}
                      />
                    ) : (
                      <img src={Logo} alt="" height={42} />
                    )}
                  </Link> */}

                  <Link
                    target="_blank"
                    rel="noopener"
                    href={
                      selectedPayment === "IMPS"
                        ? VIDEO_URL_IMPS
                        : VIDEO_URL_UPI
                    }
                    display={{ xs: "none", md: "block" }}
                    sx={{ mt: 4, mb: 6, alignSelf: "end", fontWeight: "bold" }}
                    onClick={() => setShowVideo(true)}
                  >
                    <Typography sx={{ display: "flex", alignItems: "center" }}>
                      <img src={Info} alt="" height={20} />
                      <span style={{ marginLeft: "4px", fontWeight: "bold" }}>
                        Watch Video How to Deposit?
                      </span>
                    </Typography>
                  </Link>
                </Box>
                {isCheckout ? (
                  showTimer ? (
                    <>
                      <Box
                        sx={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Box
                          sx={{ m: 2, display: { xs: "block", lg: "none" } }}
                        >
                          {/* <img src={Logo} alt="" height={32} /> */}
                        </Box>
                        {/* <Link
                          href="#"
                          display={{ xs: "block", md: "none" }}
                          sx={{
                            mt: 4,
                            mb: 4,
                            mr: 2,
                            alignSelf: "end",
                            fontWeight: 700,
                          }}
                          onClick={() => setShowVideo(true)}
                        >
                          <Typography
                            sx={{ display: "flex", justifyContent: "end" }}
                          >
                            <img src={Info} alt="" height={16} />
                            <span style={{ marginLeft: "4px" }}>
                              Watch Video How to Deposit?
                            </span>
                          </Typography>
                        </Link> */}

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            flexGrow: 1,
                          }}
                        >
                          <Typography
                            variant="h4"
                            sx={{ m: 2, fontSize: 16, fontWeight: 500 }}
                          >
                            Processing payment!
                          </Typography>
                          <Box
                            sx={{
                              position: "relative",
                              display: "inline-flex",
                            }}
                          >
                            <CircularProgress
                              // variant="determinate"
                              // value={(timerValue * 100) / 30}
                              size={128}
                              thickness={4}
                            />
                            <Box
                              sx={{
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                position: "absolute",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Typography
                                variant="h4"
                                component="div"
                                color="#0B64F2"
                                sx={{ m: 0 }}
                              >
                                {timerValue}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </>
                  ) : (
                    <PaymentMethod
                      hideCard={showTimer}
                      PaymentMethodCard={
                        selectedPayment === "IMPS"
                          ? {
                              title: "Pay with IMPS",
                              description:
                                "Pay with Immediate payment services.",
                              icon: IMPSIcon,
                            }
                          : null
                      }
                    >
                      {selectedPayment === "IMPS" ? (
                        <ImpsPayment
                          showTimer={showTimer}
                          setShowTimer={setShowTimer}
                          setTimerValue={setTimerValue}
                        />
                      ) : (
                        <PaymentUpi
                          showTimer={showTimer}
                          setShowTimer={setShowTimer}
                          setTimerValue={setTimerValue}
                        />
                      )}
                    </PaymentMethod>
                  )
                ) : (
                  <>
                    <Box sx={{ p: 2 }}>
                      <Typography
                        variant="h4"
                        sx={{
                          fontSize: { xs: "16px", md: "24px", lg: "24px" },
                        }}
                        fontWeight={500}
                        gutterBottom
                      >
                        Pay with
                      </Typography>
                      <Box
                        sx={{
                          width: "100%",
                          padding: "16px",
                          paddingLeft: "1px",
                          paddingRight: "1px",
                          boxSizing: "border-box",
                        }}
                      >
                        <ToggleButtonGroup
                          exclusive
                          value={selectedPayment}
                          onChange={(event, newPayment) => {
                            if (newPayment !== null) {
                              setSelectedPayment(newPayment);
                            }
                          }}
                          sx={{ width: "100%", flexDirection: "column" }}
                        >
                          <ToggleButton
                            value="UPI"
                            sx={{
                              padding: 0,
                              textAlign: "left",
                              "&.MuiToggleButton-root": {
                                backgroundColor: "#fff",
                                border: "none",
                                "&.Mui-selected": {
                                  backgroundColor: "#fff",
                                  color: "primary.main",
                                },
                                "&:hover": {
                                  backgroundColor: "#fff",
                                },
                                "&:not(.Mui-selected)": {
                                  borderColor: "#E3E8EF",
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                },
                              },
                            }}
                          >
                            <PaymentOption
                              selected={selectedPayment === "UPI"}
                              icon={UPIIcon}
                              title={
                                <div className="MuiCardHeader-title">
                                  Pay with UPI Payment
                                </div>
                              }
                              description={
                                <div className="MuiCardHeader-subheader">
                                  Pay with any UPI app like Phonepe, Google Pay
                                  & more
                                </div>
                              }
                            />
                          </ToggleButton>
                          <ToggleButton
                            value="IMPS"
                            sx={{
                              padding: 0,
                              marginTop: 2,
                              textAlign: "left",
                              "&.MuiToggleButton-root": {
                                backgroundColor: "#fff",
                                border: "none",
                                "&.Mui-selected": {
                                  backgroundColor: "#fff",
                                  color: "primary.main",
                                },
                                "&:hover": {
                                  backgroundColor: "#fff",
                                },
                                "&:not(.Mui-selected)": {
                                  borderColor: "#E3E8EF",
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderRadius: 1,
                                },
                              },
                            }}
                          >
                            <PaymentOption
                              selected={selectedPayment === "IMPS"}
                              icon={IMPSIcon}
                              title={
                                <div className="MuiCardHeader-title">
                                  Pay with IMPS
                                </div>
                              }
                              description={
                                <div className="MuiCardHeader-subheader">
                                  Pay with Immediate payment services.
                                </div>
                              }
                            />
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Box>
                    </Box>

                    <Button
                      sx={{
                        borderRadius: "50px",
                        padding: "12px 30px",
                        fontSize: "1rem",
                        marginLeft: "16px",
                        marginRight: "16px",
                        marginBottom: "16px",
                      }}
                      variant="contained"
                      size="large"
                      onClick={handleRadioCheckout}
                    >
                      Continue
                    </Button>
                    <Box display={{ xs: "block", lg: "none" }} sx={{ m: 1 }}>
                      <Note />
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )}
    </SiteContext.Provider>
  );
}
