import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import { Box, Paper, Typography, Divider, Grid, Link } from "@mui/material";
import Note from "./Note";
import MobileInvoice from "./MobileInvoice";
import Info from "../assets/Info.svg";
import Logo from "../assets/logo.svg";
import { SiteContext } from "../context";
import { VIDEO_URL_IMPS, VIDEO_URL_UPI } from "../constant";

export default function PaymentInvoice() {
  const { transactionDetail, setTransactionDetail } = useContext(SiteContext);

  const InvoiceContainer = styled(Paper)(({ theme }) => ({
    backgroundColor: "#000314",
    color: "#fff",
    padding: "36px",
    paddingTop: "20px",
  }));
  const Header = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(2),
  }));

  const LineItem = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  }));

  const TotalLineItem = styled(LineItem)({
    fontWeight: "bold",
  });

  return (
    <Grid item xs={12} md={12} sx={{ overflowY: "auto" }}>
      <Box sx={{ p: 2 }} display={{ xs: "block", lg: "none" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          {transactionDetail.logo ? (
            <img
              src={`data:image/png;base64,${transactionDetail.logo}`}
              alt=""
              height={42}
            />
          ) : (
            <img src={Logo} alt="" height={42} />
          )}

          <Box display={{ md: "none" }}>
            <Link
              target="_blank"
              rel="noopener"
              href={
                transactionDetail?.availableMethod?.includes("IMPS")
                  ? VIDEO_URL_IMPS
                  : VIDEO_URL_UPI
              }
              // href={transactionDetail?.videoLink}
              sx={{ m: 1, alignSelf: "end", fontWeight: 700 }}
            >
              <Typography sx={{ display: "flex", alignItems: "center" }}>
                <img src={Info} alt="" height={20} />
                <span
                  style={{
                    fontSize: 12,
                    marginLeft: "4px",
                    fontWeight: "bold",
                  }}
                >
                  Watch Video How to Deposit?
                </span>
              </Typography>
            </Link>
          </Box>
        </Box>
        <MobileInvoice />
      </Box>
      <Box display={{ xs: "none", lg: "block" }}>
        <InvoiceContainer>
          <Header sx={{ mt: 0, mb: 6, fontWeight: 700 }}>
            {transactionDetail.logo ? (
              <img
                src={`data:image/png;base64,${transactionDetail.logo}`}
                alt="logo-desktop"
              />
            ) : (
              <img src={Logo} alt="" height={42} />
            )}
          </Header>

          <LineItem sx={{ mt: 3, mb: 3, fontWeight: 700 }}>
            <Typography variant="body1">Transaction ID</Typography>
            <Typography variant="body1">
              {" "}
              {transactionDetail.transactionId
                ? transactionDetail.transactionId
                : ""}
            </Typography>
            {/* INV5728757862207 */}
          </LineItem>
          <Divider style={{ borderColor: "#28303F" }} />
          {/* <LineItem sx={{ mt: 3, mb: 2, fontWeight: 700 }}>
            <Typography variant="body1">Subtotal</Typography>
            <Typography variant="body1">${transactionDetail.amount ? transactionDetail.amount : ""}</Typography>
          </LineItem>

          <LineItem sx={{ mt: 2, mb: 3, fontWeight: 700 }}>
            <Typography variant="body1">Tax</Typography>
            <Typography variant="body1">${transactionDetail.tax ? transactionDetail.tax : "0.00"}</Typography>
          </LineItem>
          <Divider style={{ borderColor: "#28303F" }} /> */}

          <TotalLineItem sx={{ mt: 3, mb: 3, fontWeight: 700 }}>
            <Typography variant="body1">Total</Typography>
            <Typography variant="body1">
              ₹{transactionDetail.amount ? transactionDetail.amount : ""}
            </Typography>
          </TotalLineItem>
          {localStorage.getItem("remainingSeconds") > 0 ? (
            <Grid
              style={{
                textAlign: "center",
                fontSize: 14,
                color: "red",
                fontWeight: "bold",
                textDecoration: "underline",
                marginBottom: 10,
              }}
              display={{ xs: "block", md: "none" }}
            >
              <div>
                {localStorage.getItem("remainingSeconds")} SECONDS REMAINING
              </div>

              {/* {minutes === 0 && remainingSeconds === 0 && <p>Timer expired!</p>} */}
            </Grid>
          ) : (
            <></>
          )}

          <Note />
        </InvoiceContainer>
      </Box>
    </Grid>
  );
}
