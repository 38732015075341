import React, { useContext, useEffect, useState } from "react";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import {
  Button,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { SiteContext } from "../../context";
import {
  fetcTransactionDetailsByMode,
  fetchTransactionStatus,
  submitCheckoutDetails,
} from "../../utils/service";
import { useNavigate } from "react-router-dom";
import Note from "../Note";
import ToastMessage from "../Toast";
import { ContentCopy } from "@mui/icons-material";

export default function ImpsPayment({ setTimerValue, setShowTimer }) {
  const navigate = useNavigate();
  const { transactionDetail, setTransactionDetail } = useContext(SiteContext);

  const [bankDetail, setBankDetail] = useState(transactionDetail);
  const [utrNumber, setUtrNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [toastMsg, setToastMsg] = useState();

  const [error, setError] = useState(false);

  useEffect(() => {
    getBankDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBankDetail = async () => {
    setLoading(true);
    try {
      const result = await fetcTransactionDetailsByMode(
        "IMPS",
        transactionDetail?.paymentId,
        transactionDetail?.transactionId
      );
      setLoading(false);
      if (result?.status === "success") {
        setBankDetail({
          ...result?.data,
        });
      } else {
        console.log("link expired");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchContinuousStatus = () => {
    setInterval(async () => {
      const result = await fetchTransactionStatus(
        transactionDetail?.paymentId,
        transactionDetail?.transactionId
      );
      if (result === "SUCCESS" || result === "FAILED") {
        const sign = transactionDetail?.callBackUrl?.includes("?") ? "&" : "?";
        window.location.href = `${transactionDetail?.callBackUrl}${sign}transaction_id=${transactionDetail?.transactionId}`;
      }
    }, 5000);
  };

  const startCounter = (timerStart = 30) => {
    setTimerValue(timerStart);
    setShowTimer(true);
    setInterval(() => {
      timerStart--;
      if (timerStart < 0) {
        const sign = transactionDetail?.callBackUrl?.includes("?") ? "&" : "?";
        window.location.href = `${transactionDetail?.callBackUrl}${sign}transaction_id=${transactionDetail?.transactionId}`;
      } else {
        setTimerValue(timerStart);
      }
    }, 1000);
  };

  const submitHandler = async () => {
    setDisableSubmit(true);
    const data = {
      paymentId: bankDetail?.paymentId,
      utrNumber: utrNumber,
      transactionId: bankDetail?.transactionId,
      merchantId: bankDetail?.merchantId,
      payMode: "IMPS",
    };
    try {
      const result = await submitCheckoutDetails(data);
      if (result) {
        fetchContinuousStatus();
        startCounter(30);
        // window.location.href = `${transactionDetail?.callBackUrl}?transaction_id=${transactionDetail?.transactionId}`;
      } else {
        setDisableSubmit(false);
      }
    } catch (error) {
      setDisableSubmit(false);
      setError(error?.response?.data?.message || "Something went wrong");
      setToastMsg(error?.response?.data?.message || "Something went wrong");

      // return navigate("/failed", {
      //   state: {
      //     errorMsg: error?.response?.data?.message || "Something went wrong",
      //   },
      // });
    }
  };

  const BootstrapLabel = styled(InputLabel)(({ theme }) => ({
    ".MuiInputLabel-root": {
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "26px",
    },
  }));
  const BootstrapInput = InputBase;
  const InputBaseStyle = {
    "label + &": {
      // marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      marginBottom: "8px",
      borderRadius: 4,
      position: "relative",
      // backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
      border: "1px solid",
      // borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
      fontSize: 16,
      width: "auto",
      padding: "10px 12px",
      // transition: theme.transitions.create([
      //   "border-color",
      //   "background-color",
      //   "box-shadow",
      // ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        // borderColor: theme.palette.primary.main,
      },
    },
  };

  const handleClose = () => {
    setToastMsg();
  };

  return (
    <div>
      <ToastMessage
        open={toastMsg}
        message={toastMsg}
        handleClose={handleClose}
      />
      <Box
        component="form"
        noValidate
        sx={{
          display: "grid",
          gridTemplateColumns: { sm: "1fr" },
          gap: 1,
          p: 2,
          // marginBottom: ,
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: { sm: "1fr 1fr" },
            gap: 2,
            // marginBottom: 1,
          }}
        >
          {/* <Box sx={{position: "relative", width: "100%"}}> */}
          <FormControl variant="standard">
            <BootstrapLabel shrink htmlFor="account-number-input">
              Account number
            </BootstrapLabel>
            <BootstrapInput
              sx={{ ...InputBaseStyle }}
              placeholder="Account number here"
              id="account-number-input"
              value={bankDetail?.accountNumber}
              disabled
            />

            <ContentCopy
              sx={{
                position: "absolute",
                right: 5,
                top: "50%",
                cursor: "pointer",
              }}
              onClick={() => {
                setToastMsg("Copied!");
                navigator.clipboard.writeText(bankDetail?.accountNumber);
              }}
            />
          </FormControl>
          {/* </Box> */}
          <FormControl variant="standard">
            <InputLabel shrink htmlFor="ifsc-code-input">
              IFSC Code
            </InputLabel>
            <BootstrapInput
              placeholder="IFSC Code"
              id="ifsc-code-input"
              value={bankDetail?.ifscCode}
              sx={{ ...InputBaseStyle }}
              disabled
            />
            <ContentCopy
              sx={{
                position: "absolute",
                right: 5,
                top: "50%",
                cursor: "pointer",
              }}
              onClick={() => {
                setToastMsg("Copied!");
                navigator.clipboard.writeText(bankDetail?.ifscCode);
              }}
            />
          </FormControl>
        </Box>
        <FormControl variant="standard" sx={{ width: "100%" }}>
          <InputLabel shrink htmlFor="holder-name-input">
            Account holder name
          </InputLabel>
          <BootstrapInput
            placeholder="Ex. Joy Lawson"
            id="holder-name-input"
            value={bankDetail?.accountHolderName}
            sx={{ ...InputBaseStyle }}
            disabled
          />
          <ContentCopy
            sx={{
              position: "absolute",
              right: 5,
              top: "50%",
              cursor: "pointer",
            }}
            onClick={() => {
              setToastMsg("Copied!");
              navigator.clipboard.writeText(bankDetail?.accountHolderName);
            }}
          />
        </FormControl>
        <FormControl variant="standard" sx={{ width: "100%" }}>
          <InputLabel shrink htmlFor="utr-no-input">
            Bank ref. no.
          </InputLabel>
          <BootstrapInput
            placeholder="Enter 12 digit UTR no."
            sx={{ ...InputBaseStyle }}
            id="utr-no-input"
            value={utrNumber}
            onChange={(e) => {
              const re = /^[0-9\b]+$/;
              if (
                (re.test(e.target.value) || e.target.value === "") &&
                e.target.value.length <= 12
              ) {
                setUtrNumber(e.target.value);
              }
            }}
          />
        </FormControl>

        <FormControl sx={{ width: "100%", marginTop: "10px" }}>
          <Button
            sx={{
              borderRadius: "50px",
              padding: "12px 30px",
              fontSize: "1rem",
            }}
            variant="contained"
            size="large"
            disabled={disableSubmit || utrNumber.length !== 12}
            onClick={(e) => {
              e.preventDefault();
              submitHandler();
            }}
          >
            Continue
          </Button>
        </FormControl>
        <Box display={{ xs: "block", lg: "none" }}>
          <Note />
        </Box>
      </Box>
    </div>
  );
}
