import { Card, CardContent, CardHeader } from "@mui/material";
import React from "react";

export default function PaymentMethod({ title, PaymentMethodCard, children }) {
  return (
    <div>
      <div>{title || ""}</div>
      <div>
        {PaymentMethodCard ? (
          <Card sx={{ boxShadow: "none" }}>
            <CardHeader
              sx={{marginLeft: 1, "MuiCardHeader-avatar": { border: "none" } }}
              avatar={<img src={PaymentMethodCard?.icon} alt="" />}
              title={PaymentMethodCard?.title || ""}
              subheader={PaymentMethodCard?.description || ""}
            />
          </Card>
        ) : (
          <></>
        )}
      </div>
      <CardContent>{children}</CardContent>
    </div>
  );
}
