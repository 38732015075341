import * as React from "react"
const UPI = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={80}
    height={30}
    viewBox="0 0 130.54 46.118"
    {...props}
  >
    <defs>
      <clipPath id="a" clipPathUnits="userSpaceOnUse">
        <path d="M0 216h432V0H0Z" />
      </clipPath>
    </defs>
    <g clipPath="url(#a)" transform="matrix(.35278 0 0 -.35278 -10.926 61.159)">
      <path
        d="M0 0h2.77L.196-10.751c-.382-1.595-.31-2.796.218-3.597.526-.802 1.505-1.203 2.936-1.203 1.422 0 2.588.401 3.499 1.203.912.801 1.559 2.002 1.941 3.597L11.363 0h2.806l-2.637-11.017c-.573-2.394-1.594-4.186-3.056-5.374-1.463-1.19-3.381-1.784-5.754-1.784-2.375 0-4.004.593-4.891 1.778-.888 1.184-1.043 2.978-.468 5.38z"
        style={{
          fill: "#696a6a",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(33.977 61.067)"
      />
      <path
        d="m0 0 4.406 18.405 8.372-10.823a17.354 17.354 0 0 0 1.358-2.152l2.939 12.274h2.593L15.265-.687 6.717 10.329a10.15 10.15 0 0 0-1.189 1.982L2.581 0Z"
        style={{
          fill: "#696a6a",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(47.773 43.363)"
      />
      <path
        d="m0 0 4.238 17.704h2.804L2.805 0Z"
        style={{
          fill: "#696a6a",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(67.212 43.363)"
      />
      <path
        d="m0 0 4.238 17.704h9.63l-.585-2.442H6.458l-1.057-4.414h6.825l-.604-2.527H4.796L2.805 0Z"
        style={{
          fill: "#696a6a",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(74.049 43.363)"
      />
      <path
        d="m0 0 4.238 17.704h2.804L2.805 0Z"
        style={{
          fill: "#696a6a",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(86.769 43.363)"
      />
      <path
        d="m0 0 4.238 17.704h9.63l-.585-2.442H6.458l-1.063-4.438h6.825l-.604-2.528H4.791l-1.37-5.72h6.825L9.63 0Z"
        style={{
          fill: "#696a6a",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(93.606 43.363)"
      />
      <path
        d="M0 0h2.358c1.296 0 2.29.089 2.981.266a5.61 5.61 0 0 1 1.906.895 7.492 7.492 0 0 1 1.978 2.134c.529.85.93 1.841 1.2 2.97.269 1.129.343 2.117.221 2.966-.123.852-.441 1.563-.956 2.136-.387.418-.898.718-1.536.896-.639.176-1.679.265-3.125.265H2.999Zm-3.427-2.601L.811 15.104h3.783c2.46 0 4.16-.126 5.102-.38.94-.255 1.717-.681 2.328-1.277.811-.782 1.327-1.786 1.549-3.01.221-1.226.143-2.625-.233-4.197s-.967-2.965-1.773-4.178A10.608 10.608 0 0 0 8.58-.943 9.18 9.18 0 0 0 5.699-2.22c-1.025-.253-2.553-.381-4.589-.381H.355Z"
        style={{
          fill: "#696a6a",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(109.882 45.964)"
      />
      <path
        d="M0 0h.72c1.555 0 2.634.175 3.236.521.603.346 1.008.959 1.218 1.838.226.943.109 1.609-.353 1.995-.462.387-1.434.582-2.919.582h-.72zm-.741-2.356-1.902-7.947h-2.628l4.239 17.704h4.243c1.257 0 2.166-.07 2.726-.211.558-.14 1.019-.373 1.383-.696.443-.411.734-.965.874-1.662.139-.698.109-1.466-.091-2.303A7.135 7.135 0 0 0 7.087.206C6.611-.503 6.053-1.06 5.415-1.462A5.366 5.366 0 0 0 3.7-2.151c-.627-.138-1.568-.205-2.825-.205H.322Z"
        style={{
          fill: "#696a6a",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(136.88 53.666)"
      />
      <path
        d="M0 0h4.809l-.803 4.04c-.038.257-.074.553-.105.888-.029.335-.049.708-.062 1.119A19.99 19.99 0 0 0 2.734 4.04Zm6.092-6.953-.867 4.559h-6.837l-3.095-4.559h-2.945L5.109 11.452 9.05-6.953Z"
        style={{
          fill: "#696a6a",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(148.446 50.316)"
      />
      <path
        d="m0 0 1.938 8.103-3.512 9.601h2.947l2.18-6.01c.05-.154.11-.355.179-.605.07-.25.138-.519.202-.81.176.282.357.55.538.799.181.25.364.479.549.689l5.133 5.937h2.806L4.72 8.103 2.781 0Z"
        style={{
          fill: "#696a6a",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(161.516 43.363)"
      />
      <path
        d="M0 0c.001.137.041.515.119 1.136.06.516.107.944.141 1.283a11.214 11.214 0 0 0-.59-1.21 14.258 14.258 0 0 0-.784-1.234l-6.773-9.433-2.21 9.626c-.094.396-.166.779-.214 1.149-.048.371-.081.738-.097 1.102-.096-.372-.218-.76-.367-1.168a15.425 15.425 0 0 0-.536-1.276l-3.938-8.719h-2.58l8.495 18.455 2.418-11.175c.035-.177.086-.472.148-.883.062-.411.135-.919.22-1.523.286.508.698 1.152 1.24 1.934.144.21.254.371.328.484L2.667 9.711 2.422-8.744H-.184z"
        style={{
          fill: "#696a6a",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(188.28 52.107)"
      />
      <path
        d="m0 0 4.239 17.704h9.63l-.585-2.442H6.458l-1.062-4.438h6.825l-.604-2.528H4.791l-1.369-5.72h6.825L9.629 0Z"
        style={{
          fill: "#696a6a",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(192.942 43.363)"
      />
      <path
        d="m0 0 4.406 18.405 8.372-10.823a17.354 17.354 0 0 0 1.358-2.152l2.939 12.274h2.594L15.265-.687 6.717 10.329a10.15 10.15 0 0 0-1.189 1.982L2.581 0Z"
        style={{
          fill: "#696a6a",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(205.791 43.363)"
      />
      <path
        d="m0 0-3.653-15.262h-2.805L-2.806 0h-4.585l.585 2.442H5.146L4.562 0Z"
        style={{
          fill: "#696a6a",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(234.41 58.625)"
      />
      <path
        d="m0 0 2.494 1.063c.025-.789.269-1.392.735-1.807.466-.416 1.139-.623 2.021-.623.832 0 1.552.238 2.156.714.605.475 1.004 1.112 1.195 1.91.249 1.041-.372 1.968-1.857 2.781-.208.121-.368.21-.478.267-1.675.951-2.737 1.812-3.185 2.582-.447.77-.539 1.711-.273 2.823.345 1.443 1.103 2.612 2.272 3.507 1.169.895 2.527 1.343 4.076 1.343 1.273 0 2.278-.252 3.013-.756.736-.505 1.156-1.228 1.26-2.171l-2.469-1.161c-.214.556-.496.963-.846 1.222-.351.258-.795.387-1.328.387-.754 0-1.402-.203-1.943-.604a2.776 2.776 0 0 1-1.054-1.622c-.254-1.064.483-2.055 2.212-2.974l.312-.17c1.513-.805 2.497-1.594 2.948-2.364.452-.771.541-1.732.264-2.884-.401-1.678-1.234-3.01-2.5-3.997-1.266-.987-2.776-1.481-4.527-1.481-1.471 0-2.592.346-3.364 1.04C.36-2.282-.018-1.291 0 0"
        style={{
          fill: "#696a6a",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(237.321 46.907)"
      />
      <path
        d="m0 0 4.238 17.704h2.805L2.805 0Z"
        style={{
          fill: "#696a6a",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(258.046 43.363)"
      />
      <path
        d="m0 0 4.406 18.405 8.372-10.823a17.354 17.354 0 0 0 1.358-2.152l2.939 12.274h2.593L15.265-.687 6.717 10.329a10.352 10.352 0 0 0-1.19 1.982L2.581 0Z"
        style={{
          fill: "#696a6a",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(264.883 43.363)"
      />
      <path
        d="m0 0-3.654-15.262h-2.804L-2.806 0h-4.585l.585 2.442H5.146L4.562 0Z"
        style={{
          fill: "#696a6a",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(293.502 58.625)"
      />
      <path
        d="m0 0 4.238 17.704h9.63l-.584-2.442H6.459l-1.063-4.438h6.825l-.605-2.528H4.791l-1.369-5.72h6.825L9.63 0Z"
        style={{
          fill: "#696a6a",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(296.556 43.363)"
      />
      <path
        d="M0 0h.508c1.484 0 2.513.176 3.085.531.572.355.968.983 1.182 1.887.234.975.138 1.663-.291 2.061-.425.401-1.346.6-2.76.6h-.508Zm-.712-2.237-1.897-7.922h-2.628L-.998 7.545h3.913c1.147 0 2.019-.076 2.614-.23.596-.153 1.076-.406 1.439-.762.432-.426.712-.98.842-1.657.127-.676.094-1.418-.098-2.223C7.373 1.254 6.773.143 5.913-.66c-.86-.801-1.962-1.284-3.305-1.444l4.014-8.055H3.45L-.394-2.237z"
        style={{
          fill: "#696a6a",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(314.642 53.523)"
      />
      <path
        d="m0 0 4.238 17.704h9.63l-.584-2.442H6.458l-1.057-4.414h6.826l-.605-2.527H4.797L2.805 0Z"
        style={{
          fill: "#696a6a",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(322.926 43.363)"
      />
      <path
        d="M0 0h4.81l-.803 4.04c-.039.257-.074.553-.105.888-.029.335-.049.708-.062 1.119A19.604 19.604 0 0 0 2.734 4.04Zm6.093-6.953-.867 4.559h-6.837l-3.095-4.559h-2.946L5.109 11.452 9.051-6.953Z"
        style={{
          fill: "#696a6a",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(340.045 50.316)"
      />
      <path
        d="M0 0a6.19 6.19 0 0 1-2.143 1.56c-.8.345-1.688.52-2.663.52-1.885 0-3.569-.621-5.048-1.862-1.481-1.242-2.457-2.85-2.93-4.826-.457-1.911-.258-3.486.596-4.727.854-1.242 2.158-1.864 3.909-1.864 1.023 0 2.039.186 3.051.557 1.012.371 2.033.931 3.06 1.682l-.779-3.254a10.776 10.776 0 0 0-2.761-1.245 10.762 10.762 0 0 0-2.986-.411c-1.32 0-2.488.221-3.501.665a6.046 6.046 0 0 0-2.484 1.959c-.638.846-1.047 1.843-1.222 2.993-.178 1.148-.111 2.372.199 3.671.311 1.297.83 2.516 1.555 3.657a12.426 12.426 0 0 0 2.681 3.005 11.92 11.92 0 0 0 3.42 1.978c1.215.446 2.468.671 3.757.671 1.014 0 1.944-.152 2.791-.455A7.408 7.408 0 0 0 .85 2.901Z"
        style={{
          fill: "#696a6a",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(367.356 56.763)"
      />
      <path
        d="m0 0 4.238 17.704h9.63l-.585-2.442H6.459l-1.063-4.438h6.825l-.605-2.528H4.791l-1.369-5.72h6.825L9.63 0Z"
        style={{
          fill: "#696a6a",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(367.627 43.363)"
      />
      <path
        d="M0 0h-19.283L7.535 96.855h19.284z"
        style={{
          fill: "#66686c",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(316.463 76.495)"
      />
      <path
        d="M0 0c-1.337 1.843-3.399 2.773-6.2 2.773h-106.036l-5.252-18.97h19.294v.011h77.169l-5.614-20.272h-77.171l.007.042h-19.286l-16.007-57.787h19.296l10.742 38.786h86.746c2.709 0 5.259.924 7.657 2.772 2.393 1.85 3.968 4.131 4.723 6.855L.809-6.996C1.593-4.181 1.322-1.845 0 0"
        style={{
          fill: "#66686c",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(306.452 170.288)"
      />
      <path
        d="M0 0a8.86 8.86 0 0 0-8.538-6.488h-99.491c-2.711 0-4.726.924-6.051 2.77-1.324 1.848-1.608 4.134-.851 6.857l24.276 87.387h19.301l-21.683-78.05h77.206l21.683 78.05h19.297z"
        style={{
          fill: "#66686c",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(156.12 82.541)"
      />
      <path
        d="m0 0 24.414-48.553-51.322-48.54z"
        style={{
          fill: "#27803b",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(376.586 173.169)"
      />
      <path
        d="m0 0 24.396-48.553-51.343-48.54z"
        style={{
          fill: "#e9661c",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(359.472 173.169)"
      />
    </g>
  </svg>
)
export default UPI
