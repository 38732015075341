import * as React from "react"
const Paytm = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={80}
    height={30}
    viewBox="0 0 16.838 5.285"
    {...props}
  >
    <path
      d="M0 0a3.179 3.179 0 0 1-2.999 2.127h-.029a3.165 3.165 0 0 1-2.291-.977 3.169 3.169 0 0 1-2.291.977h-.029a3.16 3.16 0 0 1-2.072-.77v.244a.468.468 0 0 1-.466.434h-2.126a.47.47 0 0 1-.47-.471V-9.981a.47.47 0 0 1 .47-.471h2.126c.237 0 .432.177.463.406l-.001 8.288a.774.774 0 0 0 .737.795h.392a.827.827 0 0 0 .449-.174.782.782 0 0 0 .288-.621l.008-8.247c0-.261.211-.472.47-.472h2.126c.251 0 .455.2.467.449l-.001 8.281a.78.78 0 0 0 .346.664c.109.07.24.117.391.131h.392a.777.777 0 0 0 .737-.795l.008-8.236a.47.47 0 0 1 .47-.471h2.126c.259 0 .47.21.47.471v8.858C.161-.521.093-.264 0 0"
      style={{
        fill: "#54c1f0",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.35278 0 0 -.35278 16.777 1.561)"
    />
    <path
      d="M0 0h-1.216v1.976a.429.429 0 0 1-.51.42C-3.074 2.026-2.804.159-5.265 0h-.239a.492.492 0 0 1-.103-.012h-.002l.002-.001a.47.47 0 0 1-.368-.457v-2.126a.47.47 0 0 1 .471-.47h1.283l-.002-9.015c0-.257.208-.465.465-.465h2.102c.256 0 .464.208.464.465l.001 9.015H0a.47.47 0 0 1 .47.47V-.47A.47.47 0 0 1 0 0"
      style={{
        fill: "#54c1f0",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.35278 0 0 -.35278 11.7 .854)"
    />
    <path
      d="M0 0h-2.126a.47.47 0 0 1-.469-.47v-4.396a.498.498 0 0 0-.498-.49h-.89a.498.498 0 0 0-.499.498L-4.49-.47a.47.47 0 0 1-.47.47h-2.126a.47.47 0 0 1-.47-.47v-4.818c0-1.83 1.305-3.135 3.136-3.135 0 0 1.374 0 1.416-.008a.495.495 0 0 0 .007-.983l-.036-.007-3.109-.011a.47.47 0 0 1-.47-.47v-2.125c0-.26.21-.47.47-.47h3.476c1.832 0 3.136 1.304 3.136 3.135V-.47A.47.47 0 0 1 0 0"
      style={{
        fill: "#233266",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.35278 0 0 -.35278 9.001 .854)"
    />
    <path
      d="M0 0v-1.312a.499.499 0 0 0-.498-.499l-1.349-.001V.817h1.349A.497.497 0 0 0 0 .319Zm.187 3.896H-4.46a.461.461 0 0 1-.461-.461V1.352l.001-.012-.001-.029v-9.428c0-.256.192-.465.43-.471h2.166c.259 0 .47.21.47.47l.008 3.231H.187c1.702 0 2.888 1.181 2.888 2.89v2.999c0 1.709-1.186 2.894-2.888 2.894"
      style={{
        fill: "#233266",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.35278 0 0 -.35278 1.742 2.228)"
    />
    <path
      d="M0 0v-.332a.49.49 0 0 0-.028-.147A.497.497 0 0 0-.501-.8h-.885c-.276 0-.501.21-.501.468v.401l-.001.015.001 1.067V1.485l.001.003c.001.257.224.465.5.465h.885c.277 0 .501-.209.501-.468Zm-.338 8.875h-2.95c-.261 0-.472-.198-.472-.441v-.827l.001-.016-.001-.018V6.44c0-.257.224-.467.5-.467h2.809a.489.489 0 0 0 .423-.45v-.274c-.025-.241-.199-.417-.411-.437H-1.83c-1.85 0-3.168-1.229-3.168-2.955V-.615c0-1.716 1.133-2.937 2.97-2.937h3.855c.692 0 1.253.524 1.253 1.169v8.067c0 1.956-1.008 3.191-3.418 3.191"
      style={{
        fill: "#233266",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.35278 0 0 -.35278 4.854 3.989)"
    />
  </svg>
)
export default Paytm
