import axiosInstance from "./axiosInstance";
import { API_URL, IV, KEY } from "../constant";
import CryptoJS from "crypto-js";

var key = CryptoJS.enc.Latin1.parse(KEY);
var iv = CryptoJS.enc.Latin1.parse(IV);

const getHeaderKey = (txnID) => {
  return CryptoJS.AES.encrypt(`payzeasy#9876~${txnID}`, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.ZeroPadding,
  });
};

export const fetchInititalTransactionDetails = async (
  paymentId,
  transactionId
) => {
  try {
    const encrypted = getHeaderKey(transactionId);
    const response = await axiosInstance.get(
      `${API_URL}payment/${paymentId}/${transactionId}`,
      {
        headers: {
          iv: encrypted,
        },
      }
    );
    return response?.data ?? null;
  } catch (error) {
    throw error;
  }
};

export const fetchTransactionStatus = async (paymentId, transactionId) => {
  try {
    const encrypted = getHeaderKey(transactionId);
    const response = await axiosInstance.get(
      `${API_URL}payment/getTxnStatus/${paymentId}/${transactionId}`,
      {
        headers: {
          iv: encrypted,
        },
      }
    );
    return response?.data ?? null;
  } catch (error) {
    return null;
  }
};
export const fetcTransactionDetailsByMode = async (
  mode,
  paymentId,
  transactionId
) => {
  try {
    const encrypted = getHeaderKey(transactionId);

    const response = await axiosInstance.get(
      `${API_URL}payment/${mode}/${paymentId}/${transactionId}`,
      {
        headers: {
          iv: encrypted,
        },
      }
    );
    return response?.data ?? null;
  } catch (error) {
    return error;
  }
};

export const submitCheckoutDetails = async (data) => {
    const encrypted = getHeaderKey(data?.transactionId || "");
    const response = await axiosInstance.post(API_URL + `payment/addUTRDetails`, data, {
      headers: {
        iv: encrypted,
      },
    });
    return response?.data ?? null;
};

export const cancelTxn = async (paymentId, transactionId) => {
  try {
    const encrypted = getHeaderKey(transactionId);
    const response = await axiosInstance.get(
      `${API_URL}payment/cancel/${paymentId}/${transactionId}`,
      {
        headers: {
          iv: encrypted,
        },
      }
    );
    return response?.data ?? null;
  } catch (error) {
    return error;
  }
};
