import { Box, Card, CardContent, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function Failed() {
  const { state } = useLocation();

  useEffect(() => {
    if (
      (state?.errorMsg?.includes("Vpn") || state?.errorMsg?.includes("VPN")) &&
      state.status
    ) {
      const timer = setTimeout(() => {
        window.location.href = state.status;
      }, 10000); // 10000 milliseconds = 10 seconds

      // Cleanup function to clear the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [state?.errorMsg, state.status]);

  return (
    <>
      <Card
        className="text-center card-large"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          // border: "1px solid black",
        }}
      >
        <CardContent>
          {/* <Typography variant="h6" sx={{ color: "red", p: 1 }}>Invalid Request</Typography> */}

          <Box
            sx={{
              border: "2px solid red",
              p: 2,
              color: "red",
              textAlign: "center",
            }}
          >
            <Typography sx={{ fontWeight: 500 }} variant="body2">
              {state?.errorMsg || "Something Went Wrong"}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
