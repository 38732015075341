import axios from "axios";

// Create an Axios instance with default configuration
const axiosInstance = axios.create();

// Track requests in progress
let requestQueue = [];
let lastRequestTime = {};
// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const now = Date.now();
    const url = config.url;

    // Check if a similar request is already in progress within the last 5 seconds
    if (lastRequestTime[url] && now - lastRequestTime[url] < 2000) {
      // Cancel the request if it's a duplicate within 5 seconds
      return Promise.reject("Duplicate request canceled");
    }

    // Update last request time for this URL
    lastRequestTime[url] = now;
    // Add request to queue
    requestQueue.push(config);

    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Remove request from queue after successful response
    requestQueue = requestQueue.filter(
      (req) =>
        req.method !== response.config.method || req.url !== response.config.url
    );

    return response;
  },
  (error) => {
    // Remove request from queue after error response
    requestQueue = requestQueue.filter(
      (req) =>
        req.method !== error.config.method || req.url !== error.config.url
    );

    // Do something with response error
    return Promise.reject(error);
  }
);

export default axiosInstance;