import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { SiteContext } from "../../context";

export default function Note() {
  const { transactionDetail, setTransactionDetail } = useContext(SiteContext);

  const NoteText = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(2),
    color: "#fff",
  }));

  return (
    <>
      <NoteText
        color="text.secondary"
        sx={{ mt: { xs: 2, lg: 10 }, ml: 1, mr: 1, color: "#9B9B9B" }}
      >
        <Typography sx={{ color: { xs: "#111729", lg: "#fff", fontSize: 14 } }}>
          Note:{" "}
          <span style={{ color: "#9B9B9B" }}>
            {transactionDetail?.englishDescription ||
              "User are requested to make payment on the given details to avoid any delays and other consequences and also requesting to fill the mandatory fileds,if not submitted properly within 1hourof the transaction payemnt will be deemed as zero. False claims will has legal consequences."}
          </span>
        </Typography>
      </NoteText>
      <NoteText
        color="text.secondary"
        sx={{ ml: 1, mr: 1, mt: 2, mb: 2, color: "#9B9B9B" }}
      >
        <Typography sx={{ color: { xs: "#111729", lg: "#fff", fontSize: 14 } }}>
          नोट:{" "}
          <span style={{ color: "#9B9B9B" }}>
            {transactionDetail?.hindiDescription ||
              "उपयोगकर्ता से किसी भी देरी और अन्य परिणामों से बचने के लिए दिए गए विवरण पर भुगतान करने का अनुरोध किया जाता है और लेनदेन भुगतान के 1 घंटे के भीतर ठीक से जमा नहीं किए जाने पर अनिवार्य फ़ील्ड भरने का अनुरोध शून्य माना जाएगा। झूठे दावों के कानूनी परिणाम होंगे"}
          </span>
        </Typography>
      </NoteText>
    </>
  );
}
